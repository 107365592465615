import QRCode from "qrcode.react";
import { Box, theme, Text } from "@fivehealth/botero";
import "./QrCode.css";

import styled from "styled-components";
import EllipsisBlue from "./assets/images/ellipsis-blue.svg";
import EllipsisClear from "./assets/images/ellipsis-clear.svg";
import Redo from "./assets/images/redo.svg";
import DocAvatar from "./assets/images/doc-avatar.svg";

const StyledHeader = styled(Box)`
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 16px;
    margin-bottom: 10px;
  }
`;

const StyledQrCodeContainer = styled(Box)`
  @media (max-width: 576px) {
    margin-bottom: 0px;
  }
`;

const StyledStep = styled(Box)`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 5px;
  }
`;

const SubContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  &[type="header"] {
    flex: 0.4;
    @media (max-width: 575.98px) {
      flex: 0.1;
    }
  }

  &[type="qrcode"] {
    flex: 1;
    @media (max-width: 575.98px) {
      flex: 1.5;
    }
  }

  &[type="steps"] {
    flex: 1;
    @media (max-width: 575.98px) {
      flex: 1.5;
      align-items: flex-start;
    }
  }
`;

const Step = ({ children }) => <StyledStep alignItems="center">{children}</StyledStep>;

const QrCode = ({ value, error, qrCodeSessionExpired, reload }) => {
  const renderIcon = (icon) => <Box ml={2} mb="-10px" mr={2} as="img" width={32} src={icon} />;

  return (
    <Box data-testid="qr-code-container" display="flex" width="100%" flex="1" flexDirection="row">
      <Box flex="1" justifyContent="space-around" display="flex" flexDirection="Column">
        <SubContainer type="header" alignItems="center" pl="5%" pr="5%">
          <StyledHeader fontWeight={600} fontFamily="Inter">
            To use Bot MD on your computer:
          </StyledHeader>
        </SubContainer>
        <SubContainer type="qrcode" alignItems="center">
          <StyledQrCodeContainer
            cursor="pointer"
            onClick={reload}
            style={{
              border: `10px solid ${theme.colors.primary}`,
              width: "fit-content",
              borderRadius: "32px",
              overflow: "hidden",
              display: "inline-grid",
              textAlign: "center",
              position: "relative",
            }}
          >
            {(qrCodeSessionExpired || error) && (
              <Box width="100%" height="100%" zIndex={1} position="absolute" display="flex" justifyContent="center" alignItems="center" bg="rgba(255, 255, 255, .8)">
                <Box borderRadius="50%" height={200} width={200} display="flex" justifyContent="center" flexDirection="column" alignItems="center" bg={theme.colors.primary}>
                  <Box>
                    <Box height={32} width={32} as="img" src={Redo} />
                  </Box>
                  <Box>
                    <Text fontSize="16px" lineHeight="20px" fontWeight="normal" fontFamily="Inter" color="white">
                      CLICK TO RELOAD
                      <br />
                      QR CODE
                    </Text>
                  </Box>
                </Box>
              </Box>
            )}

            <Box bg="rgba(255, 255, 255, 1)" borderRadius="50%" position="absolute" top="50%" left="50%" p="2px" mt="-27px" ml="-27px">
              <Box height={36} width={36} p="5px" pr="7px" as="img" src={DocAvatar} />
            </Box>
            <QRCode style={{ padding: "10px" }} data-testid="qr-code" value={value} />
          </StyledQrCodeContainer>
        </SubContainer>
        <SubContainer type="steps" pl="5%" pr="5%">
          <Box>
            <Step>1. Open Bot MD{renderIcon(DocAvatar)} app on your phone.</Step>
            <Step>
              2. Tap into Menu{renderIcon(EllipsisBlue)}or{renderIcon(EllipsisClear)}on the top right and select Bot MD Web.
            </Step>
            <Step>3. Point your phone to this screen to capture the QR code.</Step>
          </Box>
        </SubContainer>
      </Box>
    </Box>
  );
};

export default QrCode;
