import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { gql, useMutation } from "@apollo/client";
import { pickBy } from "lodash";
import LoadingOverlay from "./LoadingOverlay";

const loginRequest = {
  response_mode: "query",
  scopes: ["User.Read"],
};

export const AUTHORIZATION_FLOW_GQL = gql`
  mutation authorizationFlow($input: HeimdallAuthorizationFlowInput!) {
    heimdallAuthorizationFlow(input: $input) {
      redirectTo
    }
  }
`;

const MicrosoftLogin = ({ onThrowError, orgKey }) => {
  const [loading, setLoading] = useState(false);
  const { instance, inProgress, accounts } = useMsal();
  const [apiData, setApiData] = useState(null);

  const [authorizationFlowMutation, { data: authorizationSuccessData }] = useMutation(AUTHORIZATION_FLOW_GQL, {
    onSuccess: (response) => {
      // delete localstorage keys
      setApiData(response);
      setLoading(false);
      // TODO: look if everything can be cleared in one go
      localStorage.removeItem("redirect_to");
      localStorage.removeItem("applicationInput");
      localStorage.removeItem("uid");
      localStorage.removeItem("client_id");
      localStorage.removeItem("gql_uri");
      localStorage.removeItem("triggerLogin");
    },
    onError: (error) => onThrowError(`Error calling Auth flow [authorizationFlowMutation] : ${error}.`),
  });

  useEffect(() => {
    if (authorizationSuccessData) {
      const {
        heimdallAuthorizationFlow: { redirectTo },
      } = authorizationSuccessData;
      const session = redirectTo ? redirectTo.split("session=")[1] : "";
      if (session) {
        window.parent.postMessage(
          {
            session,
          },
          "*"
        );
      }
      window.location.assign(redirectTo);
    }
    return true;
  }, [authorizationSuccessData]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && instance.getAllAccounts().length > 0) {
      setLoading(true);
      if (apiData) {
        // Skip data refresh if already set - adjust logic for your specific use case
        return;
      }

      const tokenRequest = {
        account: accounts[0], // This is an example - Select account based on your app's requirements
        scopes: ["User.Read"],
      };

      // Acquire an access token
      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          setApiData(response);
          // Call your API with the access token and return the data you need to save in state
          authorizationFlowMutation({
            variables: {
              input: pickBy({
                providerApplicationUid: localStorage.getItem("uid"),
                applicationInput: {
                  organization_key: orgKey,
                  allow_guest: false,
                },
                providerInput: {
                  access_token: response.accessToken,
                },
                redirectTo: localStorage.getItem("redirect_to"),
              }),
            },
          });
        })
        .catch(async (e) => {
          // Catch interaction_required errors and call interactive method to resolve
          if (e instanceof InteractionRequiredAuthError) {
            onThrowError(`Instanceof InteractionRequiredAuthError [acquireTokenSilent] : ${e}.`);
            await instance.acquireTokenRedirect(tokenRequest);
          } else {
            onThrowError(`Not an instanceof InteractionRequiredAuthError [acquireTokenSilent] : ${e}.`);
          }
          throw e;
        });
    } else if (instance.getAllAccounts().length === 0) {
      setTimeout(async () => {
        localStorage.setItem("triggerLogin", true);
        instance.loginRedirect(loginRequest);
      }, 1000);
    }
  }, [inProgress, accounts, instance, loading, apiData]);

  if (loading || inProgress === InteractionStatus.Login) {
    return <LoadingOverlay />;
  }

  return (
    <div className="App">
      <AuthenticatedTemplate />
    </div>
  );
};

export default MicrosoftLogin;
