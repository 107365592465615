/* eslint-disable react/button-has-type */

import { Box, Flex, Text } from "@fivehealth/botero";
import React from "react";
import BotCryingAvatar from "./assets/images/bot-crying-avatar.svg";

const getDescription = (description) => {
  let descriptionText = description;
  if (typeof description === "string") {
    const statusCodeMatch = description.match(/\b(500|502|504)\b/);
    if (statusCodeMatch) {
      const statusCode = parseInt(statusCodeMatch[0], 10);
      switch (statusCode) {
        case 500:
          descriptionText = "Something went wrong on our end. Please try again later.";
          break;
        case 502:
          descriptionText = "We’re having trouble connecting to the service, you might want to check your internet connection and try again later.";
          break;
        case 504:
          descriptionText = "The server is taking too long to respond. Please try again in a moment.";
          break;
        default:
          descriptionText = description;
      }
    } else if (description.includes("Failed to fetch")) {
      descriptionText = "We’re having trouble connecting to the server. Please check your internet connection and make sure you’re not using a VPN, then try again";
    }
  }
  return (
    <Box m="18px">
      <Text color="fullShade" fontSize="16px" fontWeight="600" textAlign="center">
        {descriptionText}
      </Text>
      <Text mt={2} color="darkestShade" fontSize="14px" fontWeight="400" textAlign="center" lineHeight="24px">
        If the problem persists, please contact
        <a
          style={{
            marginLeft: "6px",
            textDecoration: "underline",
            color: "#256BF6",
            lineHeight: "24px",
          }}
          href="mailto:customersupport@botmd.io"
        >
          customer support
        </a>
        .
      </Text>
    </Box>
  );
};

const ErrorPage = ({ description = "" }) => (
  <Flex width="100%" paddingTop="180px" justifyContent="center" alignItems="center">
    <Flex flexDirection="column" justifyContent="center" alignItems="center" width="504px">
      <Box as="img" src={BotCryingAvatar} mb={2} width="104px" />
      {getDescription(description)}
    </Flex>
  </Flex>
);

export default ErrorPage;
