import React from "react";
import "./LoadingOverlay.css";
import Lottie from "react-lottie-player";
import lottieJson from "./lottie-spinner-yellow.json";

const LoadingOverlay = () => (
  <div className="loading-overlay" data-testid="loading-overlay">
    <Lottie
      loop
      play
      animationData={lottieJson}
      style={{
        width: 300,
        height: 300,
      }}
    />
  </div>
);

export default LoadingOverlay;
