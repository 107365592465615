/* eslint-disable react/button-has-type */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import LoadingOverlay from "./LoadingOverlay";

const LogoutView = () => {
  const { logout } = useAuth0();
  useEffect(() => {
    logout();
    Sentry.captureException(`Logging user out, error with internal login flow`);
  }, []);

  return <LoadingOverlay />;
};

export default LogoutView;
