export const IS_PRODUCTION = () => !/staging|ngrok|localhost/i.test(window.location.href);

const msalConfig = {
  responseMode: "query",
  auth: {
    clientId: "", // This is the ONLY mandatory field that you need to supply.
    authority: "https://login.microsoftonline.com/6ef68169-639f-4d8f-8b7a-c981fab3c31b", // Defaults to 'https://login.microsoftonline.com/common'
    redirectUri: "", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: "localhost:3000/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
};

export default msalConfig;
