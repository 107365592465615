const IS_PRODUCTION = () => !/staging|ngrok|localhost|192.168.1.57/i.test(window.location.href);
const IS_UAT_ENV = () => /uat.staging/i.test(window.location.href);

let environment;
if (IS_PRODUCTION()) {
  environment = "production";
} else if (IS_UAT_ENV()) {
  environment = "uat";
} else {
  environment = "staging";
}

export default {
  environment,
  IS_PRODUCTION,
};
