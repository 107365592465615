import queryString from "query-string";
import { ApolloProvider } from "@apollo/client";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Login, { client } from "./Login";

function App() {
  const params = queryString.parse(window.location.search);

  return (
    <ApolloProvider client={client}>
      <Router>
        <Login redirectTo={params.redirectTo} />
      </Router>
    </ApolloProvider>
  );
}

export default App;
