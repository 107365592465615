/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { Box, Flex } from "@fivehealth/botero";
import { Text } from "@fivehealth/botero/build/components/Text/Text";

const styles = {
  input: {
    paddingLeft: 14,
    height: 40,
    fontSize: 13,
    width: "100%",
    border: "1px solid #f1f1f1",
    borderRadius: 2,
    color: "rgba(0, 0, 0, 0.87)",
    boxSizing: "border-box",
  },
  button: {
    minHeight: 44,
    backgroundColor: "rgb(21, 101, 192)",
    width: "100%",
    height: 70,
    color: "#fff",
    fontSize: 13,
    letterSpacing: 1.4,
    cursor: "pointer",
    border: "1px solid #f1f1f1",
  },
  link: { textAlign: "center", color: "rgba(0, 0, 0, 0.54)", textDecoration: "none", fontWeight: 500 },
  text: { textAlign: "center", color: "rgba(0, 0, 0, 0.54)" },
};

const HEIMDALL_OTP_EMAIL_REQUEST = gql`
  mutation heimdallOtpFlowEmailRequest($email: HeimdallOtpFlowInput!) {
    heimdallOtpFlow(input: $email) {
      otpSent
    }
  }
`;

const HEIMDALL_OTP_EMAIL_VERIFY = gql`
  mutation heimdallOtpFlowEmailVerify($emailWithOtp: HeimdallOtpFlowInput!) {
    heimdallOtpFlow(input: $emailWithOtp) {
      token
    }
  }
`;

const InternalLogin = ({ uid, providerApplicationData, onLoginSuccess, onThrowError }) => {
  const [isHeimdalOtpEmailSent, setIsHeimdalOtpEmailSent] = useState(false);
  const [heimdalEmail, setHeimdalEmail] = useState("");
  const [heimdalOtp, setHeimdalOtp] = useState("");

  const [heimdalOtpEmailVerifyMutation, { data: heimdalOtpEmailVerifyData }] = useMutation(HEIMDALL_OTP_EMAIL_VERIFY, {
    onError: (error) => onThrowError(`Error performing authorization flow: ${error}`),
  });

  const [heimdalOtpEmailMutation, { data: heimdalOtpEmailData }] = useMutation(HEIMDALL_OTP_EMAIL_REQUEST, {
    onError: (error) => onThrowError(`Error performing authorization flow: ${error}`),
  });

  const handleVerifyEmailOTP = () => {
    heimdalOtpEmailVerifyMutation({
      variables: {
        emailWithOtp: {
          providerApplicationUid: uid,
          iiType: "EMAIL",
          iiValue: heimdalEmail,
          otp: heimdalOtp,
        },
      },
    });
  };

  const handleSendEmailOTP = () => {
    heimdalOtpEmailMutation({
      variables: {
        email: {
          providerApplicationUid: uid,
          iiType: "EMAIL",
          iiValue: heimdalEmail,
        },
      },
    });
  };

  const onLoginSuccessHandler = (token) => {
    if (token) {
      onLoginSuccess({ accessToken: token });
    }
  };
  useEffect(() => {
    if (heimdalOtpEmailVerifyData?.heimdallOtpFlow?.token) {
      onLoginSuccessHandler(heimdalOtpEmailVerifyData.heimdallOtpFlow.token);
    }
  }, [heimdalOtpEmailVerifyData]);

  useEffect(() => {
    if (heimdalOtpEmailData) {
      setIsHeimdalOtpEmailSent(true);
      setHeimdalOtp("");
    }
  }, [heimdalOtpEmailData]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (isHeimdalOtpEmailSent) handleVerifyEmailOTP();
      else handleSendEmailOTP();
    }
  };

  // Return customized lock screen
  const url = providerApplicationData?.heimdallProviderApplication?.settings?.auth0?.options?.theme?.logo;
  const defaultUrl = "https://einstein.staging.botmd.io/static/media/botmd_logo.56265f22ebb02a0df55467fe3cb8e942.svg";
  return (
    <Box width={300}>
      <Flex height={100} width={300} backgroundColor="rgba(241, 241, 241, 0.8)" style={{ justifyContent: "center" }}>
        <Box as="img" src={url || defaultUrl} mt={9} height={85} width={!url && 110} backgroundColor="rgba(241, 241, 241, 0.8)" />
      </Flex>
      <Box p={20}>
        <Text style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.54)" }} fontSize={13} mb={15} lineHeight={1.8}>
          For login support issues,please contact customer support@botmd.io
        </Text>
        <input value={isHeimdalOtpEmailSent ? heimdalOtp : heimdalEmail} style={styles.input} onChange={(e) => (isHeimdalOtpEmailSent ? setHeimdalOtp(e.target.value) : setHeimdalEmail(e.target.value))} placeholder={isHeimdalOtpEmailSent ? "OTP" : "your email"} onKeyDown={handleKeyDown} />
        {isHeimdalOtpEmailSent && (
          <Text style={{ color: "rgba(0, 0, 0, 0.54)" }} fontSize={11} lineHeight={1.8}>
            An OTP has been sent to this email address
          </Text>
        )}
      </Box>

      <Box backgroundColor="rgba(241, 241, 241, 0.8)">
        <Text style={styles.text} fontSize={13} p={15} lineHeight={1.8}>
          By signing up, you agree to our{" "}
          <a style={styles.link} href="https://botmd.com/en/legal.html#privacy-policy">
            terms of service and privacy policy
          </a>
          .
        </Text>
      </Box>
      <button onClick={isHeimdalOtpEmailSent ? handleVerifyEmailOTP : handleSendEmailOTP} style={styles.button}>
        {isHeimdalOtpEmailSent ? "VERIFY OTP" : "SEND OTP"}
      </button>
    </Box>
  );
};

export default InternalLogin;
